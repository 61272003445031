
import { Component } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'
import printJS from 'print-js'
import * as _ from 'lodash'

import { getUserRole } from '@/utils/utils'
import zButton from '@/components/base/z-button.vue'
import Clipboard from 'clipboard'

@Component({
	components: { zButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i', value) : '-'
		}
	}
})
export default class extends Emitter {
	private user_role: any = ''
	private has_label: any = ''

	private channelInfo = {
		checkAll: false,
		list: Array<any>(),
		checkList: Array<any>(),
		total: 0,
		rawData: [],
		isIndeterminate: false
	}
	private rawData: any[] = []
	private shelfInfo = {
		checkAll: false,
		list: Array<any>(),
		checkList: Array<any>(),
		total: 0,
		isIndeterminate: false
	}

	private userRoles: any = {}
	private getUserRoleTitle() {
		return this.userRoles[this.user_role]?.name || '全部'
	}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}

	get list() {
		const channelWhiteList = this.channelInfo.checkList.map(
			item => item.channel_id
		)
		const shelfWhiteList = this.shelfInfo.checkList.map(item => item.shelf_area)
		const list = []
		let row = {
			shelf_number: '',
			package_code: '',
			payed: '',
			channel: '',
			channel1: '',
			shelf_number1: '',
			package_code1: '',
			payed1: '',
			label: '',
			label1: ''
		}
		for (const key in this.channelInfo.list) {
			this.channelInfo.list[key].total = 0
		}
		for (const key in this.shelfInfo.list) {
			this.shelfInfo.list[key].total = 0
		}
		this.channelInfo.total = 0
		for (const packagettr of this.rawData) {
			//判断是否显示该线路的包裹
			if (
				channelWhiteList.length &&
				!channelWhiteList.includes(packagettr.channel_id)
			) {
				continue
			}
			if (
				shelfWhiteList.length &&
				!shelfWhiteList.includes(packagettr.shelf_area)
			) {
				continue
			}

			if (this.has_label == 1 && !packagettr.platform_label) {
				continue
			}

			if (Number(this.has_label) == 2 && !!packagettr.platform_label) {
				continue
			}
			this.channelInfo.list[packagettr.channel_id].total += 1
			this.shelfInfo.list[packagettr.shelf_area].total += 1
			this.channelInfo.total += 1
			// //更新渠道包裹数量
			if (!row.package_code) {
				row.shelf_number = packagettr.shelf_code
				row.package_code = packagettr.package_attr_num
				row.payed = packagettr.payed
				row.channel = packagettr.channel_name
				row.label = packagettr.platform_label
			} else {
				row.shelf_number1 = packagettr.shelf_code
				row.package_code1 = packagettr.package_attr_num
				row.payed1 = packagettr.payed
				row.channel1 = packagettr.channel_name
				row.label1 = packagettr.platform_label
			}
			//判断是否写入队列
			if (!row.package_code1) continue
			list.push(row)
			//重置当前行
			row = {
				shelf_number: '',
				package_code: '',
				payed: '',
				channel: '',
				channel1: '',
				shelf_number1: '',
				package_code1: '',
				payed1: '',
				label: '',
				label1: ''
			}
		}
		if (row.package_code) {
			list.push(row)
		}
		this.shelfInfo.total = this.channelInfo.total
		return list
	}
	keydownEventListener(e: any) {
		if (
			e.keyCode == 80 &&
			(navigator.userAgent.match('Mac') ? e.metaKey : e.ctrlKey)
		) {
			this.printDocument()
			e.preventDefault()
		}
	}
	userRoleChange() {
		this.shelfInfo.checkList = []
		this.channelInfo.checkList = []
		this.getPackages()
	}
	deactivated() {
		//移除快捷键
		document.removeEventListener('keydown', this.keydownEventListener)
	}

	created() {
		this.getUserRole()
		this.getPackages()
		document.addEventListener('keydown', this.keydownEventListener)
	}

	handleShelfCheckAllChange(val: any) {
		this.shelfInfo.isIndeterminate = false
		if (val) {
			this.shelfInfo.checkList = Object.values(this.shelfInfo.list)
			return
		}
		this.shelfInfo.checkList = []
	}
	handleCheckAllChange(val: any) {
		this.channelInfo.isIndeterminate = false
		if (val) {
			this.channelInfo.checkList = Object.values(this.channelInfo.list)
			return
		}
		this.channelInfo.checkList = []
	}

	handleCheckedChange(value: any) {
		const total = Object.values(this.channelInfo.list).length
		this.channelInfo.checkAll = value.length === total
		this.channelInfo.checkList = value
		this.channelInfo.isIndeterminate = value.length > 0 && value.length < total
	}
	handleShelfCheckedChange(value: any) {
		const total = Object.values(this.shelfInfo.list).length
		this.shelfInfo.checkAll = value.length === total
		this.shelfInfo.checkList = value
		this.shelfInfo.isIndeterminate = value.length > 0 && value.length < total
	}

	printDocument() {
		printJS({
			printable: this.list,
			documentTitle: this.getUserRoleTitle(),
			properties: [
				{ field: 'channel', displayName: '渠道', columnSize: '12%' },
				{ field: 'shelf_number', displayName: '货架', columnSize: '8%' },
				{ field: 'package_code', displayName: '包裹编号' },
				{ field: 'channel1', displayName: '渠道', columnSize: '12%' },
				{ field: 'shelf_number1', displayName: '货架', columnSize: '8%' },
				{ field: 'package_code1', displayName: '包裹编号' }
			],
			gridStyle: 'border: 2px solid #C0C0C0;text-align: center',
			type: 'json'
		})
	}

	async getPackages() {
		const { data } = await this.$axios
			.get(`/v1/jobline/payed/package_attr?role=${this.user_role}`)
			.catch(e => {
				this.$message.error(e.message)
				return { data: null }
			})
		const { package_attr_list, shelf_areas, channels } = data
		this.channelInfo.total = this.shelfInfo.total = package_attr_list.length
		this.shelfInfo.list = shelf_areas
		this.channelInfo.list = channels
		this.rawData = package_attr_list
	}
	async copyPackageNumbers(event: any) {
		const codes: any = []
		for (const item of this.list) {
			if (item.package_code) codes.push(item.package_code)
			if (item.package_code1) codes.push(item.package_code1)
		}
		if (!codes.length) {
			return this.$message.error('复制失败,没有单号')
		}
		const clipboard: any = new Clipboard(event.target, {
			text: () => codes.join('\n')
		})
		clipboard.on('success', () => {
			this.$message({ type: 'success', message: '复制成功' })
			clipboard.off('error')
			clipboard.off('success')
			clipboard.destroy()
		})
		clipboard.on('error', () => {
			this.$message({ type: 'warning', message: '复制失败!' })
			clipboard.off('error')
			clipboard.off('success')
			clipboard.destroy()
		})
		clipboard.onClick(event)
	}
}
