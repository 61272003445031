var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("待出库包裹")]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { attrs: { gutter: 30, span: 24 } }, [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "search-form",
                  attrs: { "label-width": "100px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择类型",
                              },
                              on: { change: _vm.userRoleChange },
                              model: {
                                value: _vm.user_role,
                                callback: function ($$v) {
                                  _vm.user_role = $$v
                                },
                                expression: "user_role",
                              },
                            },
                            _vm._l(_vm.userRoles, function (item) {
                              return _c("el-option", {
                                key: item.role,
                                attrs: { label: item.name, value: item.role },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否有面单" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.has_label,
                                callback: function ($$v) {
                                  _vm.has_label = $$v
                                },
                                expression: "has_label",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                { attrs: { value: "1", label: "是" } },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-option",
                                { attrs: { value: "2", label: "否" } },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "包裹渠道" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            indeterminate: _vm.channelInfo.isIndeterminate,
                          },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.channelInfo.checkAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelInfo, "checkAll", $$v)
                            },
                            expression: "channelInfo.checkAll",
                          },
                        },
                        [_vm._v("全选(" + _vm._s(_vm.channelInfo.total) + ")")]
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.handleCheckedChange },
                          model: {
                            value: _vm.channelInfo.checkList,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelInfo, "checkList", $$v)
                            },
                            expression: "channelInfo.checkList",
                          },
                        },
                        _vm._l(_vm.channelInfo.list, function (item) {
                          return _c(
                            "el-checkbox",
                            { key: item.channel_id, attrs: { label: item } },
                            [
                              _vm._v(
                                _vm._s(item.channel_name) +
                                  "(" +
                                  _vm._s(item.total) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货架区域" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            indeterminate: _vm.shelfInfo.isIndeterminate,
                          },
                          on: { change: _vm.handleShelfCheckAllChange },
                          model: {
                            value: _vm.shelfInfo.checkAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.shelfInfo, "checkAll", $$v)
                            },
                            expression: "shelfInfo.checkAll",
                          },
                        },
                        [_vm._v("全选(" + _vm._s(_vm.shelfInfo.total) + ")")]
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.handleShelfCheckedChange },
                          model: {
                            value: _vm.shelfInfo.checkList,
                            callback: function ($$v) {
                              _vm.$set(_vm.shelfInfo, "checkList", $$v)
                            },
                            expression: "shelfInfo.checkList",
                          },
                        },
                        _vm._l(_vm.shelfInfo.list, function (item) {
                          return _c(
                            "el-checkbox",
                            { key: item.shelf_area, attrs: { label: item } },
                            [
                              _vm._v(
                                _vm._s(item.shelf_area) +
                                  "(" +
                                  _vm._s(item.total) +
                                  ")"
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-header" },
            [
              _c(
                "z-button",
                {
                  attrs: { size: "medium", type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.printDocument()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#icon-icon_btn_print" },
                      }),
                    ]
                  ),
                  _vm._v("打 印"),
                ]
              ),
              _c(
                "z-button",
                {
                  attrs: {
                    size: "medium",
                    type: "primary",
                    plain: "",
                    icon: "el-icon-document-copy",
                    disabled: !this.list.length,
                  },
                  on: { click: _vm.copyPackageNumbers },
                },
                [_vm._v("复制单号")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                { staticStyle: { width: "90%" }, attrs: { data: _vm.list } },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "channel", label: "渠道", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.row.channel) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf_number",
                      label: "货架编号",
                      width: "110",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.shelf_number) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_code",
                      label: "包裹单号",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  target: "_blank",
                                  href: `/dashboard?logistics_code=${scope.row.package_code}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.package_code))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "payed", label: "支付时间", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatDate")(scope.row.payed)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "label", label: "是否有面单", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.label
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "success" } },
                                  [_vm._v("是")]
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "danger" } },
                                  [_vm._v("否")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "channel1", label: "渠道", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.channel1) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf_number1",
                      label: "货架编号",
                      width: "110",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.shelf_number1) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_code1",
                      label: "包裹单号",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  target: "_blank",
                                  href: `/dashboard?logistics_code=${scope.row.package_code1}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.package_code1))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "payed1", label: "支付时间", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatDate")(scope.row.payed1)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "label1",
                      label: "是否有面单",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.label1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "success" } },
                                  [_vm._v("是")]
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "danger" } },
                                  [_vm._v("否")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }